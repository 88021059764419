<template>
  <div>
    <v-layout wrap justify-center v-if="appType == 'laundryadmin'">
      <v-flex xs12 lg8 pt-6>
        <v-layout wrap>
          <v-flex xs12 lg4 px-2 px-lg-0>
            <v-card
              height="150px"
              class="gradcard"
              @click="$router.push({ path: '/clothStatus' })"
            >
              <v-layout fill-height align-content-center wrap white--text>
                <v-flex
                  xs12
                  text-center
                  class="nsebold"
                  style="font-size: 42px"
                >
                  {{ st.studentcount }}
                </v-flex>
                <v-flex
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 25px"
                >
                  Students In
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg4 px-2 px-lg-4>
            <v-card
              height="150px"
              class="gradcard"
              @click="$router.push({ path: '/clothStatus' })"
            >
              <v-layout fill-height align-content-center wrap white--text>
                <v-flex
                  xs12
                  text-center
                  class="nsebold"
                  style="font-size: 42px"
                >
                  {{ st.clothincount }}
                </v-flex>
                <v-flex
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 25px"
                >
                <span> Laundry In</span> <br/>
                <span class="nsregular" style="font-size:18px !important">(Today)</span> 
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg4 px-2 px-lg-0>
            <v-card
              height="150px"
              class="gradcard"
              @click="$router.push('/clothStatus?val=Pending')"
            >
              <v-layout fill-height align-content-center wrap white--text>
                <v-flex
                  xs12
                  text-center
                  class="nsebold"
                  style="font-size: 42px"
                >
                  {{ st.clothoutcount }}
                </v-flex>
                <v-flex
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 25px"
                >
                  Total Pending
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout> </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="appType == 'foodstockadmin'">
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 lg2 md2 sm2 
             class="hidden-sm-and-down nsbold">
            <v-navigation-drawer permanent height="100vh">
              <v-list dense nav>
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  link
                  :to="item.route"
                >
                  <v-list-item-icon>
                    <v-img height="20px" contain :src="item.src"></v-img>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-flex>
          <v-flex 
            xs12
            lg10
            sm10
            md10
          >
            <router-view> </router-view>
          </v-flex>
           
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="appType == 'AssetAdmin'">
      <v-flex xs12>
        <v-layout wrap justify-center>
          <!-- <v-flex xs12 lg2 md2 sm2 class="hidden-sm-and-down">
            <v-navigation-drawer permanent height="100vh">
              <v-list dense nav>
                <v-list-item
                  v-for="item in assetItems"
                  :key="item.title"
                  link
                  :to="item.route"
                >
                  <v-list-item-icon>
                    <v-img :src="item.src"></v-img>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-flex> -->
          <!-- <v-flex xs12 lg10 sm10 md10> -->
          <v-flex xs12>
            <router-view> </router-view>
          </v-flex>
        </v-layout>
         <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../store";
export default {
  data() {
    return {
      items: [
        {
          title: "Home",
          src: require("../assets/images/5.png"),
          route: "/admin",
        },
        {
          title: "Add Products",
          src: require("../assets/images/3.png"),
          route: "/addproduct",
        },
        {
          title: "Supplier",
          src: require("../assets/images/1.png"),
          route: "/supplier",
        },
        {
          title: "Purchase",
          src: require("../assets/images/7.png"),
          route: "/purchase",
        },
        {
          title: "Menu",
          src: require("../assets/images/6.png"),
          route: "/menu",
        },
        {
          title: "Analysis",
          src: require("../assets/images/4.png"),
          route: "/analysis",
        },
        {
          title: "Purchase History",
          src: require("../assets/images/8.png"),
          route: "/stock",
        },
      ],
      // items: [
      //   {
      //     title: "Home",
      //     src: require("../assets/images/clsndtchr.png"),
      //     route: "/",
      //   },
      //   {
      //     title: "Add Products",
      //     src: require("../assets/images/analytic.png"),
      //     route: "/addproduct",
      //   },
      //   {
      //     title: "Supplier",
      //     src: require("../assets/images/student.png"),
      //     route: "/supplier",
      //   },
      //   {
      //     title: "Purchase",
      //     src: require("../assets/images/staff.png"),
      //     route: "/purchase",
      //   },
      //   {
      //     title: "Menu",
      //     src: require("../assets/images/noticeboard.png"),
      //     route: "/menu",
      //   },
      //   {
      //     title: "Purchase History",
      //     src: require("../assets/images/exmlogo.svg"),
      //     route: "/analysis",
      //   },
      //   {
      //     title: "Bill History",
      //     src: require("../assets/images/clsndtchr.png"),
      //     route: "/stock",
      //   },
      // ],
      assetItems: [
        {
          title: "Home",
          src: require("../assets/images/5.png"),
          route: "/assetAdmin",
        },
        {
          title: "Add Item",
          src: require("../assets/images/add.png"),
          route: "/addAsset",
        },
        {
          title: "Purchase",
          src: require("../assets/images/purchase.png"),
          route: "/purchaseAsset",
        },
        {
          title: "Purchase History",
          src: require("../assets/images/purchasehistory.png"),
          route: "/assetHistory",
        },
        {
          title: "Report",
          src: require("../assets/images/report.png"),
          route: "/assetReport",
        },
      ],
      st: [],
      showsnackbar: false,
      msg: null,
      appLoading: false,
      flag: false,
      series: [
        {
          name: "IN",
          data: [0, 0, 0, 42, 33],
        },
        {
          name: "OUT",
          data: [0, 0, 0, 40, 13],
        },
        // {
        //   name: 'Students',
        //   data: [0, 0, 0, 99, 75]
        // }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: "straight",
          dashArray: [0, 8, 5],
        },
        title: {
          text: "Laundry Statistics",
          align: "left",
          class: "nsbold",
          font: "28px",
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              ""
            );
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + " (mins)";
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val + " per session";
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  computed: {
    appType() {
      return store.state.userType;
    },
  },

  mounted() {
    this.flag = true;

    if (this.appType == "laundryadmin") this.getData();
    // if(this.appType == 'foodstockadmin')
    // this.graph();
  },
  methods: {
    getImageURL(src) {
      let images = require.context("../assets/images/");
      return images("./" + src);
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/count",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.st = response.data;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.gradcard {
  background: transparent
    linear-gradient(104deg, #786cc9 0%, #766BC0 52%, #6f60d6 100%) 0% 0% !important;
}
</style>